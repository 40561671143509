Modal container
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.ReactModal__Overlay{
  background-color: rgba(128, 128, 128, 0.75) !important;
}

/* Modal content */
.modal-content {
  background-color: #fff;
  width: 350px;
  height: 600px; /* Increased height */
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: modalFadeIn 0.3s ease-in-out;
  /* max-height: 70vh; */
  overflow-y: auto;
  margin-top: 30px;
}

/* Modal title */
.modal-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Modal body */
.modal-body {
  font-size: 16px;
  margin-bottom: 20px;
}

/* Modal footer */
.modal-footer {
  display: flex;
  justify-content: flex-end;
}

/* Modal close button */
.modal-close {
  cursor: pointer;
  font-size: 16px;
  color: #888;
  margin-left: 10px;
}
.ReactModal__Content{
  position: absolute;
  border: none !important;
  background: none !important;
  overflow: none !important;
  border-radius: 0px !important;
  outline: none !important;
  padding: 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Animation for modal */
@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
